import { API_URL, CrudService } from 'ui-shared';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ContratoEntity } from '../../models/index';

@Injectable({ providedIn: 'root' })
export class ContratoDataService extends CrudService<ContratoEntity> {
    
    protected url: string;
    
    constructor(
        @Inject(API_URL) urlBase: string,
        @Inject(HttpClient) protected httpClient: HttpClient
    ) {
        super(urlBase, httpClient);
        this.url = `${urlBase}/v1/contrato`;
    }
}
