import { API_URL, CrudService, ApiResult } from 'ui-shared';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ContaEntity } from '../../models/index';

@Injectable({ providedIn: 'root' })
export class ContaDataService extends CrudService<ContaEntity> {

  protected url: string;

  constructor(
    @Inject(API_URL) urlBase: string,
    @Inject(HttpClient) protected httpClient: HttpClient
  ) {
    super(urlBase, httpClient);
    this.url = `${urlBase}/v1/conta`;
  }

  gerarToken(contaId: any)
  {
    const apiUrl = `${this.url}/${contaId}/gerartoken`;
    return this.httpClient.post<ApiResult<ContaEntity>>(apiUrl, {});
  }
}
