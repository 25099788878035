import { API_URL } from 'ui-shared';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LoginEntity, LoginMfaEntity, LoginMicrosoftEntity } from "@identidade/models";

@Injectable({ providedIn: 'root' })
export class LoginDataService {
    
    protected url: string;
    
    constructor(
        @Inject(API_URL) urlBase: string,
        @Inject(HttpClient) protected httpClient: HttpClient
    ) {
        this.url = `${urlBase}/v1/autenticacao`;
    }
    
    logar(login: LoginEntity) {
        return this.httpClient.post(this.url + '/login', login);
    }
    
    logarMfa(loginMfa: LoginMfaEntity) {
        return this.httpClient.post(this.url + '/EmailCodigoMfa', loginMfa);
    }
    
    logarMicrosoft(loginMicrosoft: LoginMicrosoftEntity) {
        return this.httpClient.post(this.url + '/LoginMicrosoft', loginMicrosoft);
    }
    
    recuperarSenha(email: string, redirect: string) {
        return this.httpClient.get(`${this.url}/recuperarsenha?email=${email}${redirect !== undefined? '&redict='+redirect : ''}`);
    }
    
    reenviarCodigoMfa(email: string) {
        return this.httpClient.get(`${this.url}/reenviarEmailCodigoMfa?email=${email}`);
    }
    
    validaRedirect(url: string) {
        return this.httpClient.get(`${this.url}/ValidaRedirect?url=${url}`);
    }
}
